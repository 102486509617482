import { useEffect } from "react";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import "../styles/landingpage.css";
import HomeLayout from "./HomeLayout";
import Kontakt from "./Kontakt";
import ServiceSlider from "./ServiceSlider";
import TeamSlider from "./TeamSlider";
import Carrier from "./carrier";

function openInNewWindow(url: string): boolean {
    window.open(url, 'newWindow', 'width=1200,height=800,scrollbars=yes,resizable=yes');
    return false; // Verhindert das Standardverhalten des Links
}

const LandingPage = () => {
    useEffect(() => {
        document.title = 'Löffler-Müller | Startseite';
    }, []);

    return (
        <HomeLayout>
            <div className="landing-page-body">
                <section className="landingpage-section-holder">
                    <div className="landingpage-landing-section-holder">
                        <div className="img-hero">
                            <div className="landing-section-heading-holder">
                                <h1>Ihre Komplettlösungen für Türen, Fenster und Tore.</h1>
                            </div>
                            <div className="landing-section-img-holder">
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-us-section" id="aboutUs" style={{scrollMarginTop: '50px'}}>
                    <div className="section-wrapper">
                        <div className="section-heading-holder">
                            <h1 className="section-heading">Das sind wir</h1>
                        </div>
                        <div className="about-us-credentials-holder">
                            <div className="about-us-credentials-content-left">
                                <div className="about-us-credentials-content-image-holder-left">
                                </div>
                                <div className="about-us-credentials-content-paragraph-left">
                                    <h1 className="credentials-heading">Gebr. Löffler GmbH</h1>
                                    <br />
                                    <p className="credentials-main-content">Über 30 Jahre Tradition und Innovation. Wir arbeiten mit größter Motivation und höchstem Niveau an der Realisierung Ihrer Bauprojekte mit unserem Know-how als führender Baustoffhändler der Region.</p>
                                    <a className="credentials-website-link" href="https://www.bauzentrum-loeffler.de/" onClick={(e) => { e.preventDefault(); openInNewWindow('https://www.bauzentrum-loeffler.de/'); }} rel="noopener">Mehr erfahren</a>
                                </div>
                            </div>
                            <div className="about-us-credentials-content-right">
                                <div className="about-us-credentials-content-image-holder-right">
                                </div>
                                <div className="about-us-credentials-content-paragraph-right">
                                    <h1 className="credentials-heading">Müller Interior GmbH</h1>
                                    <br />
                                    <p className="credentials-main-content">Seit über einem Jahrhundert gehen wir bewusst gegen alte Konventionen an und suchen nach innovativen Lösungen. Tür- und Fenstersysteme für verschiedene Anforderungen sind unsere Spezialität.</p>
                                    <a className="credentials-website-link" href="https://www.mueller-interior.de/" onClick={(e) => { e.preventDefault(); openInNewWindow('https://www.mueller-interior.de/'); }} rel="noopener">Mehr erfahren</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
                <section className="service-section" id="service">
                    <div className="section-wrapper">
                        <div className="section-heading-holder">
                            <h1 className="section-heading">Unsere Leistungen</h1>
                        </div>
                    </div>
                    <ServiceSlider />
                </section>
                <section className="goal-section" id="goal" style={{ scrollMarginTop: '150px' }}>
                    <div className="section-wrapper">
                        <div className="goal-section-content-holder">
                            <div className="section-heading-holder">
                                <h1 className="section-heading">Unser Auftrag</h1>
                                <p className="goal-content-paragraph">
                                    Wir sind ein junges, dynamisches Unternehmen. Gegründet aus zwei traditionsreichen Firmen, welche in ihren Fachbereichen absolute Größen darstellen.
                                    <br /><br />
                                    Als Unternehmen für Komplettlösungen im Bereich Objekttüren, -fenster und -tore verstehen wir uns als technischer sowie kaufmännischer Partner in jeder Projektphase. Unser Team besteht aus ausgebildeten Technikern, Kalkulatoren und Monteuren. Unser Liefer- und Montagenetzwerk erstreckt sich deutschlandweit. Natürlich sind alle unsere Monteure passend zu den entsprechenden Anforderungen der Objekte geschult und werden durch unser Projektteam in jeder Phase der Ausführung unterstützt.
                                </p>
                            </div>
                            <div className="goal-content-image-holder"></div>
                        </div>
                    </div>
                </section>
                {/* <section className="reference-section">
                <div className="reference-heading-holder">
                    <h1 className="reference-heading">Referenzen unserer Arbeit</h1>
                    <p className="reference-subheading">Unsere Referenzliste gibt Ihnen den besten Überblick Über die...</p>
                </div>
                <div className="reference-content-holder">
                    <ReferencesSlider />
                </div>
            </section> */}
                <section className="team-section" id="team" style={{scrollMarginTop: '170px'}}>
                    <div className="section-wrapper">
                        <div className="section-heading-holder">
                            <h1 className="section-heading">Unser Team</h1>
                        </div>
                    </div>
                    <TeamSlider />
                </section>
                <section className="goal-section" id="carrier" style={{ scrollMarginTop: '150px' }}>
                    <div className="section-wrapper">
                        <div className="carrier-section-content-holder">
                            <div className="carrier-section-heading-holder">
                                <h1 className="section-heading">Karriere</h1>
                            </div>
                                <p className="carrier-content-paragraph">
                                    Du suchst eine Arbeitsumgebung, in der Du wirklich etwas bewegen kannst? Dann bist du bei uns genau richtig! Wir sind ein junges, dynamisches Unternehmen mit flachen Hierarchien und Start-up-Feeling. Hier zählt jede Idee, jede Stimme – und vor allem deine Initiative. Bei uns übernimmst Du Verantwortung ab dem ersten Tag und gestaltest aktiv die Entwicklung unseres Unternehmens mit. Eigenständiges Arbeiten, Teamspirit und der Wille, gemeinsam Großes zu schaffen, treiben uns an.
                                </p>
                                <h3 className="carrier-content-paragraph">Unsere offenen Stellen</h3>
                                <p className="carrier-content-paragraph">
                                    <a
                                        href="/pdf/loeffler+mueller-projektleiter.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="carrier"
                                    >
                                        Projektleiter (m/w/d)&nbsp;&#10138;
                                    </a>
                                </p>
                                <p className="carrier-content-paragraph">Nichts passendes dabei? Bewirb dich jederzeit initiativ unter <a href="mailto:talente@loeffler-mueller.de" className="carrier">talente@loeffler-mueller.de&nbsp;&#10138;</a></p>
                        </div>
                    </div>
                </section>
                <section className="contact-interest-section" style={{ scrollMarginTop: '275px' }} id="kontakt">
                    <div className="contact-interest-content">
                        <Kontakt></Kontakt>
                    </div>
                </section>
            </div >
        </HomeLayout>
    );
};

export default LandingPage;